import {Component, Renderer2, ViewChild} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import * as semverSort from 'semver-sort';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  @ViewChild('iframe') iframe;

  components: any[] = [{label: '@yuuvis/core', value: 'core'}, {label: '@yuuvis/framework', value: 'framework'}];
  coreVersions: any[] = [];
  frameworkVersions: any[] = [];
  versions: any[] = [];
  selectedVersion: any;
  selectedComponent: any;

  constructor(private http: HttpClient, private renderer: Renderer2) {
    // grab the available versions
    http.get('assets/core-versions.txt', {responseType: 'text'})
      .subscribe(
        res => {
          if (res && res.length) {
            this.coreVersions = semverSort.desc(res.replace(/[\n\r]/g, '').split(','))
              .map(e => e.trim())
              .map(e => {
                return {
                  label: e,
                  value: e
                };
              });

            this.selectedComponent = this.components[0].value;
            this.versions = this.coreVersions;
            this.loadDocs(this.versions[0]);
          }
        }
      );

    http.get('assets/framework-versions.txt', {responseType: 'text'})
      .subscribe(
        res => {
          if (res && res.length) {
            this.frameworkVersions = semverSort.desc(res.replace(/[\n\r]/g, '').split(','))
              .map(e => e.trim())
              .map(e => {
                return {
                  label: e,
                  value: e
                };
              });
          }
        }
      );
  }

  selectComponent(component) {
    this.selectedComponent = component.value;
    this.versions = this.selectedComponent === 'core' ? this.coreVersions : this.frameworkVersions;
    this.loadDocs(this.versions[0]);
  }

  loadDocs(version) {
    const available = !!this.versions.find(i => i.value === version.value);
    if (available) {
      this.selectedVersion = version.value;
      const el = this.iframe.nativeElement;
      this.renderer.setAttribute(el, 'src', `docs/${this.selectedComponent}/${this.selectedVersion}/index.html`);
    } else {
      console.error('Invalid docs version');
    }
  }
}
